<template>
	<div class="HSMask">
		<div class="HSDialog">
			<div class="HSDialogTitleLine">
				<div class="HSDialogTitle">赠送优惠券</div>
				<img class="HSDialogCloseImg" src="../../../assets/img/HS/close.png" @click="close"/>
			</div>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<el-table :data="tableData" height="500px">
				<el-table-column label="优惠券名称" align="center" min-width="150">
					<template slot-scope="scope">
						<el-checkbox v-model="scope.row.checked">
							{{scope.row.title}}
						</el-checkbox>
					</template>
				</el-table-column>
				<el-table-column label="规格" min-width="120">
					<template slot-scope="scope">
						{{scope.row.ruleInfo.ruleName}}
						<span v-if="scope.row.activity_type==1">满{{scope.row.money_top}}减{{scope.row.money_reduce}}</span>
						<span v-if="scope.row.activity_type==2">满{{scope.row.rebate_top}}打{{scope.row.rebate*10}}折 最高抵扣{{scope.row.rebate_limit}}
							元</span>
					</template>
				</el-table-column>
				<el-table-column label="优惠卷类型" width="120">
					<template slot-scope="scope">
						{{scope.row.secondCategoryInfo.name}}
					</template>
				</el-table-column>
				<el-table-column label="使用时间" width="220">
					<template slot-scope="scope">
						<span>{{$public.FTime(scope.row.availableTime.start,'YYYY-MM-DD')}}</span> 至 <span>{{$public.FTime(scope.row.availableTime.end,'YYYY-MM-DD')}}</span>
					</template>
				</el-table-column>
				<el-table-column label="说明" width="120" >
					<template slot-scope="scope">
						<div style="">
							<span class="remark">{{scope.row.description}}</span>
							<el-popover v-if="scope.row.description" placement="top-start" width="200" trigger="hover" :content="scope.row.description">
								<el-button slot="reference" type="text" icon="el-icon-view"></el-button>
							</el-popover>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="赠送数量" width="100" >
					<template slot-scope="scope">
						<el-input v-model="scope.row.num" @input="inputNum(scope.row)"></el-input>
					</template>
				</el-table-column>
			</el-table>
			<!-- //////////////////////////////////////////////////////////////////////////////// -->
			<div style="height: 80px;"></div>
			<div class="HSDialogButtonLine">
				<div class="HSDialogButtonCancel" @click="close">取消</div>
				<div class="HSDialogButtonConfirm" @click="submit">确认</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				tableData:[],
			}
		},
		computed:{
			
		},
		props:{
			userInfo:{
				default:()=>{
					return {}
				}
			},
		},
		mounted(){
			this.getTableData()
		},
		methods:{
			inputNum(row){
				row.num = this.$public.replaceToDouble(row.num)
			},
			// 关闭
			close(){
				this.$emit("submit",{type:"close",data:""})
			},
			// 提交
			submit(){
				let couponList = []
				for(let i=0;i<this.tableData.length;i++){
					if(this.tableData[i].checked===true){
						if(this.tableData[i].num<1){
							this.$message({
								message: '赠送数量输入有误',
								type: 'error'
							})
							return ;
						}
						couponList.push({
							"couponId": this.tableData[i].virtualId,
							"couponCount":  this.tableData[i].num,
						})
					}
				}
				if(couponList.length===0){
					this.$message({
						message: '请选择优惠券',
						type: 'error'
					})
					return ;
				}
				let params = {
					"memberId": this.userInfo.id,
					"memberName":  this.userInfo.basicInfo.nickName,
					"memberMobile":  this.userInfo.basicInfo.userMobile,
					"otherParam": "",
					"remark": "",
					"couponList": couponList,
				}
				let loading = this.$loading()
				this.$http.post("/customer/MemberCoupon/ManySendCoupon",params).then(res=>{
					loading.close()
					if(res.code===0){
						this.$emit("submit",{type:"success",data:""})
					}
				})
			},
			getTableData(){
				let params = {
					"applyType":'',  // 宠物类型 0:通用 1:猫 2:狗 3:异常
					"categoryId": '',  // 商品类型编号
					"keyword": '',
					"onlineStatus":"1",
					"pageIndex": 1,
					"pageSize": 1000,
				}
				let loading = this.$loading()
				this.$http.post("/product/virtual/coupons/list",params).then(res=>{
					loading.close()
					if(res.code===0){
						let tableData = res.data.list
						for(let i=0;i<tableData.length;i++){
							tableData[i].checked = false
							tableData[i].num = 1
						}
						this.tableData = tableData
					}
				})
			},
		}
	};
</script>
<style lang="scss" scoped>
	
</style>
