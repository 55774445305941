<template>
	<div style="width:100%;padding:10px 20px;box-sizing: border-box;text-align: right;background-color: #fff;">
		<!-- :hide-on-single-page="total<=pageSize" -->
		<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
			:current-page="pageIndex" :page-sizes="pageSizes" :page-size="pageSize"  layout="total, sizes, prev, pager, next, jumper"
			:total="total"></el-pagination>
	</div>
</template>
<script>
export default {
	data() {
		return {

		}
	},
	props: {
		total: {
			type: Number,
			default: 0,
		},
		pageSizes: {
			default: () => {
				return []
			}
		},
		pageSize: {
			type: Number,
			default: 10
		},
		pageIndex: {
			type: Number,
			default: 1,
		}
	},
	mounted() { },
	methods: {
		handleSizeChange(val) {
			this.$emit('size-change', val)
		},
		handleCurrentChange(a) {
			this.$emit('change', a)
		}
	}
}
</script>
<style></style>
