<template>
	<div class="page">
		<div class="title">
			<div class="titleL">会员优惠券</div>
			<div class="titleR">
				<el-button type="infor" @click="$router.back()">返回</el-button>
			</div>
		</div>
		<!-- 搜索栏 -->
		<div class="tabView">
			<div class="tabViewB">
				<div class="search">
					<div class="searchTitle">优惠券类型</div>
					<el-select v-model="activityType" @change="toSearch" placeholder="请选择" style="width: 143px;">
						<el-option v-for="item in [{value:-1,label:'全部'},{value:1,label:'满减券'},{value:2,label:'折扣券'},]" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="search">
					<div class="searchTitle">使用状态</div>
					<el-select v-model="useStatus" @change="toSearch" placeholder="请选择" style="width: 143px;">
						<el-option v-for="item in [{value:-1,label:'全部'},{value:0,label:'不可用'},{value:1,label:'未使用'},{value:2,label:'已使用'},{value:3,label:'已过期'},]" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</div>
				<div class="tabViewBR">
					<el-button type="primary" v-if="$buttonAuthority('huiyuan-huiyuanList-see-sendCoupon')" @click="dialogSendYouhuiquan_state=true">赠送优惠券</el-button>
					<!-- <el-button>导出</el-button>
					<el-button type="primary" >新建商品</el-button> -->
				</div>
			</div>
		</div>
		<!-- 表格 -->
		<div class="tableBox">
			<el-table :data="tableData" height="100%">
				<el-table-column min-width="200" prop="couponName" label="优惠券名称">
				</el-table-column>
				<el-table-column min-width="100" prop="name" label="类型">
					<template slot-scope="scope" >
						{{scope.row.secondCategoryInfo.name}}
					</template>
				</el-table-column>
				<el-table-column min-width="180" prop="province" label="领取时间">
					<template slot-scope="scope" >
						{{$public.FTime(scope.row.receiveTime)}}
					</template>
				</el-table-column>
				<el-table-column min-width="220" prop="province" label="适用时间">
					<template slot-scope="scope" >
						{{$public.FTime(scope.row.availableTime.start,'YYYY-MM-DD')}} ~ {{$public.FTime(scope.row.availableTime.end,'YYYY-MM-DD')}}
					</template>
				</el-table-column>
				<el-table-column min-width="100" prop="city" label="使用状态">
					<template slot-scope="scope" >
						{{scope.row.useStatus.title}}
					</template>
				</el-table-column>
				<el-table-column min-width="100" prop="city" label="订单号">
					<template slot-scope="scope" >
						{{scope.row.orderNo}}
					</template>
				</el-table-column>
				<el-table-column min-width="120" prop="city" label="优惠券状态">
					<template slot-scope="scope" >
						{{scope.row.status.name}}
					</template>
				</el-table-column>
			</el-table>
		</div>
		<page :pageSizes="pageSizes"	@size-change="handleSizeChange" :pageIndex="page.pageIndex" :pageSize="page.pageSize" :total="page.total" @change="changePage"></page>
		<div v-if="dialogSendYouhuiquan_state">
			<dialogSendYouhuiquan :userInfo="userDetail" @submit="submit_dialogSendYouhuiquan"></dialogSendYouhuiquan>
		</div>
	</div>
</template>
<script>
	import dialogSendYouhuiquan from "./dialogSendYouhuiquan.vue"
	import page from "../../../components/page.vue"
	export default {
		components: {dialogSendYouhuiquan,page},
		data() {
			return {
				// 用户信息
				userDetail:{},
				// search
				activityType:-1,
				useStatus:-1,
				// table
				page:{
					pageIndex:1,
					pageSize:10,
					total:0,
				},
				pageSizes: [10, 15,30,60],
				tableData:[],
				// 赠送优惠券
				dialogSendYouhuiquan_state:false,
			}
		},
		computed:{
			
		},
		mounted() {
			this.getDetail();
			this.getTableData()
		},
		methods: {
			// 获取用户详情
			getDetail(){
				let params = {
					id:this.$route.query.id
				}
				let loading = this.$loading()
				this.$http.get("/customer/Member/Get",params).then(res=>{
					loading.close()
					if(res.code===0){
						this.userDetail = res.data
					}
				})
			},
			// 搜索
			toSearch(){
				this.page.pageIndex=1;
				this.getTableData()
			},
			// 获取优惠券信息
			changePage(num){
				this.page.pageIndex = num;
				this.getTableData()
			},
			handleSizeChange(val) {
			this.page.pageIndex = 1;
			this.page.pageSize = val
			this.getTableData()
		},
			getTableData(){
				let params = {
					"pageIndex":this.page.pageIndex,
					"pageSize": this.page.pageSize,
					"couponId": "",
					"memberId": this.$route.query.id,
					"shopId": "",
					"activityType": this.activityType,  // 活动类型-1：全部、1：满减活动、2：折扣活动
					"useStatus":this.useStatus,  // 使用状态 -1:全部 0：不可用，1：未使用 2：已使用 3：已过期
					"receiveStartTime": "",
					"receiveEndTime": "",
					"useStartTime": "",
					"useEndTime": "",
					"otherParam": "",
					"keyword": "",
				}
				let loading = this.$loading()
				this.$http.post("/customer/MemberCoupon/List",params).then(res=>{
					loading.close()
					if(res.code===0){
						this.tableData = res.data.list
						this.page.total = res.data.count
					}
				})
			},
			// 赠送优惠券
			submit_dialogSendYouhuiquan(obj){
				this.dialogSendYouhuiquan_state = false;
				if(obj.type==="success"){
					this.$message({
						message: '赠送成功',
						type: 'success'
					})
					this.getTableData();
				}
			}
			
		}
	}
</script>
<style lang="scss" scoped>
	.page {
		width:100%;height: 100%;border-radius: 20px;overflow: hidden;color: $fontColor;background-color: #fff;
		.title{
			display: flex;justify-content: space-between;border-bottom: 1px solid #f2f2f2;height: 68px;box-sizing: border-box;padding:14px 20px;
			.titleL{
				font-size: 16px;color:$fontColor;line-height: 40px;
			}
			.titleR{
				
			}
		}
		// 搜索信息
		.tabView {
			height: 80px;
			border-radius: 20px;
			background: #fff;
			display: flex;
			flex-direction: column;
		
			.tabViewT {
				height: 80px;
				border-bottom: solid 1px #F2F2F2;
				display: flex;
				align-items: center;
		
				.tabItem {
					width: 116px;
					line-height: 40px;
					text-align: center;
					font-size: 16px;
					border-radius: 40px;
					margin-left: 20px;
					cursor: pointer;
					background: #FFF0E0;
				}
		
				.active {
					background: #F77E04;
					color: #fff;
				}
			}
		
			.tabViewB {
				
				flex: 1;
				display: flex;
				align-items: center;
				padding: 0 20px;
		
				.search {
					display: flex;
					align-items: center;
					margin-right: 32px;
		
					.searchTitle {
						font-size: 14px;
						padding-right: 4px;
						color: $fontColor;
					}
				}
		
				.tabViewBR {
					flex: 1;
					display: flex;
					align-items: center;
					justify-content: flex-end;
				}
			}
		}
		.tableBox{
			height: calc(100% - 200px);
		}
		
	}
</style>
